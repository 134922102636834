<template>
  <div :class="`m-3 wrapper  wrapper--default`">
    <h2 class="text-center">Inspectie Rapport - {{ itemno }}</h2>
    <hr>
    <Loading v-if="loading" />
    <div class="flex spaceevenly">
      <div v-for="(categorie, key) in inspection_report" :key="key" class="m2">
        <div class="flex _column">
          <h3>{{ key }}</h3>
          <div v-for="(item, key) in categorie" :key="key">
            <span v-if="item.Score < 3" class="red"
              >{{ item.Titel }}: {{ item.Score }}</span
            >
            <span v-else>{{ item.Titel }}: {{ item.Score }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";

export default {
  props: ["itemno"],
  components: { Loading },
  data: () => ({
    inspection_report: null,
    loading: true,
  }),
  created() {
    this.getData(this.itemno);
  },
  watch: {
    itemno(newitemno) {
      this.loading = true;
      this.getData(newitemno);
    },
  },
  methods: {
    getData(itemno) {
      request(`inspection-report/${itemno}`, "GET").then(
        ({ inspection_report }) => {
          this.inspection_report = inspection_report;
          this.loading = false;
        }
      );
    },
  },
};
</script>
